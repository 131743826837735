import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Table } from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import { deleteIn, setIn } from 'immutable-setter';

import { ExaminationClaimRow } from "./examination_claim_row";
import { RequestQuoteButton } from "./request_quote_button";
import { currency } from '../util';
import { ExaminationOtherOptionsPanel } from "./examination_other_options_panel";
import CollapsibleCard from '../collapsible_card';

import RepairResolution from './examination_resolution/repair';
import ReplacePartsResolution from './examination_resolution/replace_parts';
import ReselectionResolution from './examination_resolution/reselection';
import SettlementResolution from './examination_resolution/settlement';
import { RTGAuthButton } from "./rtg_auth_modal";
import DefaultResolution from "./examination_resolution/default";
import PreviousExaminationsPanel from "./previous_examinations_panel";
import { NoEmailModal } from "./no_email_modal";
import { NoBillingModal } from "./no_billing_modal";

const OPTION_NAMES_MAP = {
    'repair': 'Repair',
    'replace_parts': 'Replace Parts',
    'reselection': 'Reselection',
    'settlement': 'Settlement',
    'refund': 'Refund'
};

const OPTION_COMPONENT_MAP = {
    'repair': RepairResolution,
    'replace_parts': ReplacePartsResolution,
    'reselection': ReselectionResolution,
    'settlement': SettlementResolution,
};


export class ExaminationPanel extends React.Component {
    constructor(props) {
        super(props);
        this.toggleExaminationDetails = this.toggleExaminationDetails.bind(this);
        this.submitDecision = this.submitDecision.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.loadExamination = this.loadExamination.bind(this);
        this.cancelConfirmation = this.cancelConfirmation.bind(this);
        this.proceedConfirmation = this.proceedConfirmation.bind(this);
        this.hasSettlement = this.hasSettlement.bind(this);
        this.proceedNoBilling = this.proceedNoBilling.bind(this);
        this.cancelNoBilling = this.cancelNoBilling.bind(this);

        this.state = {
            examination: null,
            selections: {},
            details_open: false,
            data: {},
            submitting: false,
            errors: {},
            show_email_modal: false,
            show_billing_modal: false,
            bypass_email_modal: false
        };
    }

    isRtgFlow() {
        let examination = this.state.examination;
        let rtg_vendor = (!examination.selected_vendor) || (examination.selected_vendor && examination.selected_vendor.alternate_flow === "rtg")|| !this.hasRepair() || false;
        return this.props.is_rtg_claim && rtg_vendor;
    }

    getExaminationURL() {
        return `/examination/${this.state.examination.id}/`;
    }

    componentDidMount() {
        this.loadExamination();
    }

    loadExamination(callback) {
        axios.get(`/api/examination/${this.props.examination}/with_billing_address/`).then((response) => {
            this.setState({
                examination: response.data,
            });
        }).then(callback);
    }

    getQuoteRequestClaim(examination_claim) {
        let quote_request = this.state.examination.quote_request;

        if (quote_request !== null) {
            let quote_request_claim = _.filter(quote_request.quote_request_claims, ((x) => x.examination_claim_id === examination_claim.id))[0];

            if (quote_request_claim !== undefined) {
                return quote_request_claim;
            }
        }

        return null;
    }

    setData() {
        let resolutions = {};

        for (let index in this.state.examination.examination_claims) {
            let examination_claim = this.state.examination.examination_claims[index];
            let option_name = this.state.selections[examination_claim.id];

            if (!resolutions.hasOwnProperty(option_name)) {
                resolutions[option_name] = [];
            }

            resolutions[option_name].push(examination_claim);
        }

        this.setState({
            data: _.map(resolutions, (examination_claims, option_name) => {
                return {
                    override: false,
                    override_reason: "",
                    notes: "",
                    option_type: option_name,
                    resolution_claims: _.map(examination_claims, ec => {
                        return {examination_claim: ec.id};
                    }),
                    can_submit: false,
                };
            })
        });
    }

    selectOption(examination_claim_id, option_name, event) {
        if (this.state.details_open) {
            return;  // disable changes while decision confirmation details are displayed
        }
        if (this.isRtgFlow() && Object.keys(this.state.selections).length > 1 && ((option_name != "repair" && this.hasRepair()) || (option_name == "repair" && !this.hasRepair()))) {
            this.setState({"errors": {submit: "You can't mix options when a Non RTG Service Tech selected for a repair."}})
            return;
        } else {
            this.setState({"errors": {}})
        }
        if (event.target.checked) {
            this.setState({
                selections: setIn(this.state.selections, [examination_claim_id], option_name)
            }, this.setData);
        } else {
            this.setState({
                selections: deleteIn(this.state.selections, [examination_claim_id])
            }, this.setData);
        }
    }

    getTotal() {
        let total = 0.0;
        // let repair_prices = [];

        for (let index in this.state.examination.examination_claims) {
            let examination_claim = this.state.examination.examination_claims[index];
            let selected_option = this.state.selections[examination_claim.id];

            if (selected_option === undefined) {
                continue;
            }

            let option = examination_claim.options[selected_option];

            if (option.amount === null) {
                return '?';
            }

            // if (selected_option === 'repair') {
            //     repair_prices.push(parseFloat(option.amount));
            // } else {
            total += parseFloat(option.amount);
            // }
        }

        // if (repair_prices.length > 0) {
        //     total += _.max(repair_prices);
        // }

        return currency(total);
    }

    canStartSubmit() {
        let examination_claims = this.state.examination.examination_claims;

        return (
            examination_claims.length > 0 && examination_claims.length === Object.keys(this.state.selections).length && this.state.data.length > 0
        );
    }

    canSubmitDecision() {
        if (!this.canStartSubmit()) {
            return false;
        }
        // Panel can submit when all resolutions have set their can_submit properties to true
        return _.reduce(this.state.data, (valid, data) => valid && data.can_submit, true);
    }

    toggleExaminationDetails() {
        if (this.state.submitting) {
            return;
        }
        this.setState({details_open: !this.state.details_open});
    }

    /* work around decimal issue with serializer. */
    fixFloats(data) {
        function isFloat(n) {
            return Number(n) === n && n % 1 !== 0;
        }

        for (let key in data) {
            let value = data[key];

            if (value === undefined) {
                continue;
            }
            if (isFloat(value)) {
                data[key] = value.toFixed(2);
            } else if (typeof value === 'object') {
                this.fixFloats(value);
            }
        }
        return data;
    }

    submitDecision() {
        let component = this;
        let data = this.fixFloats(this.state.data);

        if (!this.canSubmitDecision()) {
            return;
        }
        // if voucher can be issued, and no email, they have to intentionally issue the voucher with no email.
        if (this.props.can_issue_vouchers && !this.props.contact_email && !this.state.bypass_email_modal) {
            this.setState({
                show_email_modal: true
            })
            return;
        }

        // if there's no billing address on file, and there's a settlement, we can't proceed.
        if (this.hasSettlement() && this.state.examination.billing_address == null) {
            this.setState({
                show_billing_modal: true
            })
            return;
        }
        this.setState({submitting: true});


        axios.post(`/api/examination/${this.state.examination.id}/submit_decision/`, data).then((response) => {
            document.location = this.getExaminationURL();
        }).catch(function (error) {
            if (error.response && error.response.request.response) {
                let errors = JSON.parse(error.response.request.response);
                component.setState({errors: errors});
            } else if (error.request) {
                alert("Unable to communicate with server");
            }
        }).finally(function () {
            component.setState({submitting: false});
        });
    }

    renderResolutionComponents() {
        // transform the data array into an array of react components

        // using a copy because child components can push state changes from their constructors
        let data_array = _.cloneDeep(this.state.data);
        return data_array.map((data, index) => {
            let component_class = _.get(OPTION_COMPONENT_MAP, data.option_type, DefaultResolution);
            let component_props = {
                'key': index,
                'data': data,
                'onChange': this.onExaminationDataChange.bind(this, index),
                'examination': this.state.examination,
                'selections': this.state.selections,
                'is_rtg_claim': this.props.is_rtg_claim
            };

            return React.createElement(component_class, component_props);
        });
    }

    cancelConfirmation(e) {
        e.preventDefault();
        this.setState({
            show_email_modal: false
        });
    }

    proceedConfirmation(e) {
        e.preventDefault()
        this.setState({
                bypass_email_modal: true,
                show_email_modal: false
            }, function () {
                this.submitDecision()
            }
        )

    }

    cancelNoBilling(e) {
        e.preventDefault();
        this.setState({
            show_billing_modal: false
        });
    }

    proceedNoBilling(e) {
        e.preventDefault()
        this.setState({
                show_billing_modal: false
            }, function () {
                this.loadExamination(this.submitDecision)
            }
        )

    }

    hasSettlement() {
        let has_settlement = false;
        Object.values(this.state.selections).forEach((value, index) => {
            if (value === "settlement") {
                has_settlement = true;
            }
        });
        return has_settlement;
    }

    hasRepair() {
        let has_repair = false;
        Object.values(this.state.selections).forEach((value, index) => {
            if (value === "repair") {
                has_repair = true;
            }
        });
        return has_repair;
    }

    renderExaminationDetails() {
        return (
            <Fragment>
                <div className="d-flex align-items-baseline mt-4">
                    <h3 className="mr-5">Confirm Service Decision</h3>
                    <h5>{this.state.examination.object_title}</h5>
                </div>

                {this.renderResolutionComponents()}

                <div className="d-flex justify-content-center">
                    <Button color="primary" outline onClick={this.toggleExaminationDetails}
                            disabled={this.state.submitting}
                            className="btn-wide mr-3">Cancel</Button>
                    <Button color="primary" onClick={this.submitDecision}
                            disabled={this.state.submitting || !this.canSubmitDecision()}
                            className="btn-wide ml-3">
                        {this.state.submitting ? (
                            <span><Fa icon="spinner" spin/> Confirming</span>
                        ) : (
                            "Confirm"
                        )}
                    </Button>
                    <NoEmailModal showModal={this.state.show_email_modal} cancelConfirmation={this.cancelConfirmation}
                                  proceedConfirmation={this.proceedConfirmation}/>
                    <NoBillingModal showModal={this.state.show_billing_modal} cancelNoBilling={this.cancelNoBilling}
                                    proceedNoBilling={this.proceedNoBilling}
                                    contactId={this.state.examination.contact_id}/>

                </div>
            </Fragment>
        );
    }

    onExaminationDataChange(index, data) {
        this.setState({data: setIn(this.state.data, [index], data)});
    }

    isSecondRepair(examination_claim) {
        let selections = this.state.selections;

        let other_repairs = _.filter(
            this.state.examination.examination_claims,
            (ec) => ec.id !== examination_claim.id && selections[ec.id] === 'repair'
        );

        return other_repairs.length > 0 && (selections[examination_claim.id] !== 'repair' || parseInt(_.keys(selections)[0]) !== examination_claim.id);
    }

    getAvailableOptions() {
        let options = [];
        for (let index in this.state.examination.examination_claims) {
            let examination_claim = this.state.examination.examination_claims[index];
            for (let option in examination_claim.options) {
                if (examination_claim.options.hasOwnProperty(option)) {
                    if (!_.includes(options, option)) {
                        options.push(option)
                    }
                }
            }
        }

        return options;
    }

    renderOptionsTable() {
        return (
            <Table responsive={true}>
                <thead>
                <tr>
                    <th/>
                    {this.getAvailableOptions().map((option) =>
                        <th key={option} style={{verticalAlign: "top"}}>
                            {OPTION_NAMES_MAP[option]} &#160;<br/>
                            {(option === 'repair' && this.state.examination.status === 'open') &&
                            <a href={`/examination/${this.state.examination.id}/select-vendor/`}
                               className="btn btn-xs btn-outline-primary">
                                Select Service Tech
                            </a>
                            }
                            {(option === 'replace_parts' && this.state.examination.status === 'open' && !this.props.is_rtg_claim) &&
                            <RequestQuoteButton examination={this.state.examination}
                                                loadExamination={this.loadExamination}/>
                            }
                            {(option === 'replace_parts' && this.state.examination.status === 'open' && this.props.is_rtg_claim) &&
                            <RTGAuthButton
                                selections={this.state.selections}
                                examination={this.state.examination}
                                className="btn btn-xs btn-outline-primary"
                                title="Quote Request"
                                option_type="quote_request"
                            />
                            }
                        </th>
                    )}
                </tr>
                </thead>
                <tbody style={{fontSize: "1rem"}}>
                {this.state.examination.examination_claims.map((examination_claim, index) =>
                    <ExaminationClaimRow
                        key={examination_claim.id}
                        examination={this.state.examination}
                        examination_claim={examination_claim}
                        selected_option={this.state.selections[examination_claim.id]}
                        selectOption={this.selectOption}
                        is_second_repair={this.isSecondRepair(examination_claim)}
                        selected_vendor={this.state.examination.selected_vendor}
                        loadExamination={this.loadExamination}/>
                )}
                <tr>
                    <td colSpan={5} className={"text-danger"}>{this.state.errors.submit}</td>
                </tr>
                </tbody>
            </Table>
        );
    }

    renderMainDetails() {
        return (
            <form className="examination-option-list">
                {this.renderOptionsTable()}

                {this.state.examination.status === 'open' &&
                <Fragment>
                    <div className="total-row">
                        Selected Option Total <span className="total">{this.getTotal()}</span>
                    </div>

                    {!this.state.details_open &&
                    <div className="text-center mt-3">
                        {this.isRtgFlow() &&
                        <Fragment>
                            <RTGAuthButton
                                color="primary"
                                size="lg"
                                selections={this.state.selections}
                                examination={this.state.examination}
                                disabled={!this.canStartSubmit()}
                            />
                            <span className="divider"></span>
                        </Fragment>
                        }

                        <Button
                            color="primary"
                            size="lg"
                            disabled={!this.canStartSubmit()}
                            onClick={this.toggleExaminationDetails}
                        >
                            Submit Service Decision
                        </Button>
                    </div>
                    }
                </Fragment>
                }

                {this.state.details_open && this.renderExaminationDetails()}

            </form>
        )
    }

    render() {
        return (
            <Fragment>
                <CollapsibleCard header="Examination Options" expanded={this.props.expanded}>
                    {this.state.examination == null ? (
                        <div><Fa icon="spinner" spin/> Loading examination options</div>
                    ) : (
                        this.renderMainDetails()
                    )}
                </CollapsibleCard>

                {(this.state.examination && this.state.examination.status !== "closed") &&
                <ExaminationOtherOptionsPanel
                    examination={this.state.examination}
                    expanded={false}
                    manual_reasons={this.props.manual_reasons}
                    can_request_inspection={this.props.can_request_inspection}/>
                }

                {this.state.examination &&
                <PreviousExaminationsPanel
                    examination_id={this.state.examination.id}
                    expanded={false}/>
                }
            </Fragment>
        );
    }
}

ExaminationPanel.propTypes = {
    examination: PropTypes.number.isRequired,
    expanded: PropTypes.bool,
    is_rtg_claim: PropTypes.bool,
    can_issue_vouchers: PropTypes.bool,
    contact_email: PropTypes.string
};

ExaminationPanel.defaultProps = {
    expanded: true,
    is_rtg_claim: false,
    can_issue_vouchers: false,
    contact_email: ""
};
