import React from 'react';
import axios from 'axios';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Fa from "alpaca.js/dist/util/fa";
import FieldInput from "../forms/field_input";
import linkState from "../link_state";
import {getIn} from "immutable-setter";
import {currencyLinkState} from "../util";
import CheckBoxInput from "../forms/checkbox_input";


export class RTGAuthButton extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.sendAuth = this.sendAuth.bind(this);
        this.getDefaultValues = this.getDefaultValues.bind(this);

        this.state = {
            modal_opened: false,
            sending: false,
            loading: false,
            errors: {},
            data: {
                authorization_action: "",
                authorization_amount: 0.00,
                notes: "",
                new_email: false,
                needs_service: false,
                show_sevice_checkbox: false
            }
        };
    }

    getDefaultValues() {
        let total = 0.00;
        let actions = new Array();
        let action = "";
        let service_checkbox = false;
        if (this.props.option_type == "quote_request") {
            actions.push("Quote Request");
        } else {
            // there will ever only be one claim here.
            for (let index in this.props.examination.examination_claims) {
                let examination_claim = this.props.examination.examination_claims[index];
                let selected_option = this.props.selections[examination_claim.id];
                if (selected_option === undefined) {
                    continue;
                }
                if (selected_option == "replace_parts") {
                    actions.push("Part Order");
                    service_checkbox = true;
                    total = 0.00;
                    continue;
                }
                let option = examination_claim.options[selected_option];
                if (option.amount !== null) {
                    total += parseFloat(option.amount);
                    action = option.option_type_name;
                    if (action === 'Repair') {
                        action = action + ": ";
                        for (let i in examination_claim.selected_services) {
                            action = action + examination_claim.selected_services[i].selected_service.name;
                            actions.push(action);
                            action = "";
                        }
                    } else {
                        actions.push(action);
                    }
                } else {
                    actions.push(action);
                }
            }
        }
        return {
            authorization_amount: total.toFixed(2),
            authorization_action: actions.join(", "),
            notes: "",
            new_email: false,
            needs_service: false,
            show_service_checkbox: service_checkbox
        };
    }

    onLinkStateChange() {
        const {onChange} = this.props;

        if (onChange) {
            onChange(this.state.data);
        }
    }

    getError(paths) {
        if (this.state.errors !== undefined) {
            return getIn(this.state.errors, paths);
        }

        return null;
    }

    toggleModal() {
        if (this.state.sending) {
            return;
        }
        this.setState({
            modal_opened: !this.state.modal_opened,
            data: this.getDefaultValues()
        });
    }

    sendAuth() {
        let component = this;
        this.setState({sending: true});
        axios.post(`/api/examination/${this.props.examination.id}/send_rtg_email/`, this.state.data).then((response) => {
            this.setState({modal_opened: false, finalizing: false});
            document.location.reload();
        }).catch(function (error) {
            if (error.response) {
                let errors = JSON.parse(error.response.request.response);
                component.setState({errors: errors});
            } else if (error.request) {
                alert("Unable to communicate with server");
            }
        }).finally(function () {
            component.setState({sending: false});
        });
    }

    renderModal() {
        let data = this.state.data;
        let heading;
        let heading_description;
        if (this.props.option_type == 'quote_request') {
            heading = "Quote Request";
            heading_description = "Email a quote request to Rooms to Go";
        } else {
            heading = "Send Authorization";
            heading_description = "Send authorization information to Rooms To Go";
        }
        console.log(this.state);

        return (
            <Modal isOpen={this.state.modal_opened} toggle={this.toggleModal}
                   backdrop={this.state.sending ? "static" : true}>
                <div className="modal-header pb-0">
                    <div className="modal-title">
                        <h3 className="modal-title">{heading}</h3>
                        <p>{heading_description}</p>
                    </div>
                    <button type="button" className="close" aria-label="Close" onClick={this.toggleModal}
                            disabled={this.state.sending}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <hr className="mt-0"/>
                    <FieldInput
                        label="Authorization Action"
                        onChange={linkState(this, 'data', ['authorization_action'])}
                        value={data.authorization_action}
                        error={this.getError(['authorization_action'])}
                        readOnly={true}
                    />
                    <FieldInput
                        label="Authorization Amount"
                        onChange={currencyLinkState(this, 'data', ['authorization_amount'])}
                        value={data.authorization_amount}
                        error={this.getError(['authorization_amount'])}
                        type="number"
                    />
                    <FieldInput
                        label="Notes"
                        onChange={linkState(this, 'data', ['notes'])}
                        value={data.notes}
                        error={this.getError(['notes'])}
                        type="textarea"
                        maxLength="512"
                    />
                    <div style={{width: "100%"}}>
                        <div style={{display: "table-cell", width: "50%"}}>
                            <CheckBoxInput
                                label={"New"}
                                onChange={linkState(this, 'data', ["new_email"])}
                                checked={data.new_email}
                            >
                            </CheckBoxInput>
                        </div>
                        <div style={{display: "table-cell", width: "50%"}}>
                            {this.state.data.show_service_checkbox ?
                            <CheckBoxInput
                                label={"Needs Service"}
                                onChange={linkState(this, 'data', ["needs_service"])}
                                checked={data.needs_service}
                             /> : null }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={this.toggleModal} disabled={this.state.sending}
                            className="btn-wide">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.sendAuth} disabled={this.state.sending} className="btn-wide">
                        {this.state.sending ? (
                            <span><Fa icon="spinner" spin/> Sending </span>
                        ) : (
                            "Send Email"
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        let title;
        if (this.props.option_type == "quote_request") {
            title = "Email Quote Request";
        } else {
            title = "Email Rooms To Go";
        }

        return (
            <span>
                <Button onClick={this.toggleModal}
                        disabled={this.props.disabled}
                        color={this.props.color}
                        size={this.props.size}
                        className={this.props.className}
                >{title}</Button>

                {this.renderModal()}
            </span>
        )
    }
}

// RTGAuthButton.propTypes = {
//     resolution_id: PropTypes.number.isRequired,
// };
