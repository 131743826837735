import React, { Component, Fragment } from 'react';

import { Col, FormGroup, Label, Row, Table } from 'reactstrap';
import CheckBoxInput from "../../forms/checkbox_input";
import linkState from '../../link_state';
import FieldInput from "../../forms/field_input";
import DateInput from "../../forms/date_input";
import { currency } from '../../util';
import Select2 from '../../forms/select2';

import { ExaminationConfirmationWrapper, LabeledTotal, validate_resolution } from './common';
import DamageCodeList from "../../claims/damageCodeList";
import { setIn } from "immutable-setter";


export class RepairClaimList extends Component {
    // WARNING! this component is used in the installation and inspection modal also.

    constructor(props) {
        super(props);
    }

    onChange(path, event) {
        let resolution_claims = setIn(this.props.resolution_claims, path, event.target.value);

        // fake an event
        this.props.onChange({target: {value: resolution_claims}});
    }

    renderService(service, examination_claim_id, resolution_claim_index, service_index) {
        let examination_claim = _.find(this.props.examination.examination_claims, ec => ec.id === examination_claim_id);
        let damage = _.find(examination_claim.claim.damages, (x) => x.id === service.damage);
        let selected_service = _.find(examination_claim.selected_services, (x) => x.damage.id === service.damage);
        const service_path = [resolution_claim_index, "extra_data", "services", service_index];

        let {override} = this.props;

        return (
            <tr key={`service_${resolution_claim_index}_${service_index}`}>
                <td>
                    <DamageCodeList damage_names={damage.code_actual_names} small={false}/>
                    {selected_service !== undefined && <>
                        <strong>Service: </strong> {selected_service.selected_service.name}
                    </>}
                </td>
                <td>
                    {override ? (
                        <FieldInput
                            value={service.trip_charge}
                            onChange={this.onChange.bind(this, [...service_path, "trip_charge"])}
                            type="number"
                            style={{width: 100}}
                            min={0}/>
                    ) : (
                        currency(0)
                    )}
                </td>
                <td>
                    {override ? (
                        <FieldInput
                            value={service.price}
                            onChange={this.onChange.bind(this, [...service_path, "price"])}
                            type="number"
                            style={{width: 100}}
                            min={0}/>
                    ) : (
                        currency(service.price)
                    )}
                </td>
            </tr>
        );
    }

    renderRepairsForResolution(resolution_claim, resolution_claim_index) {
        let examination_claim = _.filter(this.props.examination.examination_claims, ec => ec.id === resolution_claim.examination_claim)[0];

        return (
            <Fragment key={`repair_${resolution_claim_index}`}>
                <h5 className="mt-4">Claim {examination_claim.claim.name}</h5>

                <Table className="mb-1">
                    <thead>
                    <tr>
                        <th style={{width: "40%"}}>Damage</th>
                        <th className="price-col">Trip Charge</th>
                        <th className="price-col">Service Charge</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {_.map(resolution_claim.extra_data.services, (service, index) =>
                        this.renderService(service, resolution_claim.examination_claim, resolution_claim_index, index)
                    )}
                    </tbody>
                </Table>
            </Fragment>
        );
    }

    render() {
        let {resolution_claims} = this.props;

        return <>
            {resolution_claims.map((resolution_claim, index) => {
                return this.renderRepairsForResolution(resolution_claim, index);
            })}
        </>;
    }
}


export default class RepairResolution extends React.Component {
    constructor(props) {
        super(props);
        let selected_vendor = props.examination.selected_vendor;
        let initial_data = this.calculateTotals(props.data);
        if (props.is_rtg_claim && selected_vendor.alternate_flow === "rtg") {
            initial_data.invoice_date = "";
            initial_data.invoice_number = "";
        }
        this.state = {
            data: initial_data
        };

        // push the calculated totals and any other changes up to the parent object
        this.props.onChange(initial_data);
    }

    onLinkStateChange() {
        // triggered after state is updated from a form field change
        this.calculateAndPush(this.state.data);
    }

    calculateAndPush(data) {
        data = this.calculateTotals(data);
        this.setState({data: data});
        this.props.onChange(data);
    }

    canSubmit(data) {
        let can_submit = validate_resolution(data);
        let selected_vendor = this.props.examination.selected_vendor;
        for (let index in data.resolution_claims) {
            let services = data.resolution_claims[index].extra_data.services;
            for (let service in services) {
                if (_.trim(services[service].trip_charge) === "") {
                    can_submit = false;
                }
                if (_.trim(services[service].price) === "") {
                    can_submit = false;
                }
            }
        }
        // in case of rooms to go,
        if (this.props.is_rtg_claim && selected_vendor.alternate_flow === "rtg") {
            // do not allow submit unless they choose status
            if (!data.sub_status) {
                can_submit = false;
            }
            // require invoice_number
            if (!data.invoice_number) {
                can_submit = false;
            }
            // require invoice_date
            if (!data.invoice_date) {
                can_submit = false;
            }
        }

        return can_submit;
    }

    calculateTotals(data) {
        // given initial data from props, or updated data after a state change, calculate totals and ensure
        // all resolution claims have the necessary extra_data.  Returns the update data object.

        // helper func to get an examination_claim object from props by id
        let examination_claims = this.props.examination.examination_claims;
        let find_examination_claim = (id) => _.filter(examination_claims, ec => ec.id === id)[0];
        let to_num = (val) => parseFloat(val) || 0;

        data.calculated_amount = 0.0;
        data.authorized_amount = 0.0;

        for (let index in data.resolution_claims) {
            let resolution_claim = data.resolution_claims[index];
            let examination_claim = find_examination_claim(resolution_claim.examination_claim);

            let amount = parseFloat(examination_claim.options[this.props.data.option_type].amount);
            data.calculated_amount += amount;

            if (data.resolution_claims[index].extra_data === undefined || !data.override) {
                data.resolution_claims[index].extra_data = this.buildInitialExtraData(examination_claim.id);
            }

            let services = data.resolution_claims[index].extra_data.services;
            // subtotals for all services in this resolution claim
            let service_total = _.reduce(services, (sum, row) => sum + to_num(row.price), 0);
            let trip_charge_total = _.reduce(services, (sum, row) => sum + to_num(row.trip_charge), 0);

            data.authorized_amount += (service_total + trip_charge_total);
        }

        data.can_submit = this.canSubmit(data);

        return data;
    }

    buildInitialExtraData(examination_claim_id) {
        // Builds the initial parts array for the extra_data of this resolution_claim

        let examination_claim = _.filter(this.props.examination.examination_claims, ec => ec.id === examination_claim_id)[0];
        let option = examination_claim.options.repair;
        let extra_data = option.extra_data || {};

        return {
            services: extra_data.services || []
        };
    }

    render() {
        let data = this.state.data;
        let examination = this.props.examination;

        return (
            <ExaminationConfirmationWrapper title="Repair" total={data.calculated_amount}>
                <Row>
                    <Col md={6}>
                        <div>
                            <Row>
                                <Col>
                                    <h5>Vendor</h5>
                                </Col>
                                <Col>
                                    {examination.selected_vendor.name}
                                </Col>
                            </Row>

                            <LabeledTotal label="Total Authorized Amount" total={data.authorized_amount}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <CheckBoxInput
                            label="Override Authorized Amount"
                            onChange={linkState(this, "data", ["override"])}
                            checked={data.override}
                        />

                        {data.override && (
                            <FieldInput
                                value={data.override_reason}
                                label="Override Reason"
                                onChange={linkState(this, "data", ["override_reason"])}/>
                        )}
                    </Col>
                </Row>

                <RepairClaimList
                    resolution_claims={data.resolution_claims}
                    examination={this.props.examination}
                    override={this.state.data.override}
                    onChange={linkState(this, "data", ["resolution_claims"])}/>

                <FieldInput
                    type="textarea"
                    label="Additional Notes to Service Tech"
                    value={data.notes}
                    onChange={linkState(this, "data", ["notes"])}/>
                {this.props.is_rtg_claim && examination.selected_vendor.alternate_flow === "rtg" && (
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <Label>Repair Status?</Label>
                                <Select2 onSelect={linkState(this, "data", ["sub_status"])}
                                         data={[{"id": "successful", "name": "Successful"}, {
                                             "id": "unsuccessful",
                                             "name": "Unsuccessful"
                                         }]}
                                         emptyLabel="--------"
                                         value={data.sub_status}/>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FieldInput
                                value={data.invoice_number}
                                label="Invoice Number"
                                onChange={linkState(this, "data", ["invoice_number"])}
                            />
                        </Col>
                        <Col sm={4}>
                            <DateInput
                                value={data.invoice_date}
                                label="Invoice Date"
                                onChange={linkState(this, 'data', ['invoice_date'])}
                                options={{maxDate: new Date()}}
                            />
                        </Col>
                    </Row>
                )}


            </ExaminationConfirmationWrapper>
        );
    }
}
